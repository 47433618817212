@font-face { font-family: "BM"; src: url("assets/BM.woff") format('woff'); }
@font-face { font-family: "BMB"; src: url("assets/BMB.woff") format('woff'); }
@font-face { font-family: "BMBI"; src: url("assets/BMBI.woff") format('woff'); }
@font-face { font-family: "BMI"; src: url("assets/BMI.woff") format('woff'); }

@keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
}
@keyframes fadeOut {
    0% { opacity: 1; }
    100% { opacity: 0; }
}
@keyframes pulse {
    0% {
        transform: scale(1.12);
        opacity:0;
    }
    30% {
        opacity:0.5;
    }
    100% {
        transform: scale(1.4);
        opacity: 0;
    }
}
@keyframes pulseLoad {
    0% {
        opacity:1;
    }
    50% {
        opacity:0.35;
    }
    100% {
        opacity:1;
    }
}

/* WALLET CONNECT MODAL */
#walletconnect-wrapper,
#walletconnect-wrapper h1,
#walletconnect-wrapper h2,
#walletconnect-wrapper h3,
#WEB3_CONNECT_MODAL_ID {
    font-family: sans-serif;
}


/* DEBUG */
/*
* {
    outline: 1px dashed blue;
    outline-offset: -1px;
}
*/

body, html {
    width: 100%;
    height: 100%;
    overflow-y: auto;
}
body {
    background: #fff;
    margin:0em;
    font-size:18px;
    font-family: "BM", serif;
}
* {
    box-sizing: border-box;
}

h1, h2, h3, h4, h5, h6 {
    margin-top:1.1em;
    font-family: "BMI", serif;
    font-weight: normal;
}
h1 {
    font-size:2em;
    margin-bottom:0.75em;
}
h2 {
    margin-top:1.5em;
    /* font-size:1.35em; */
}
p {
    font-size:1em;
    padding:0 2em;
    margin:1em auto;
    text-align: left;
}
.glyph {
    font-family: sans-serif !important;
}


/* LAYOUT */
#root {
    width: 100%;
    min-height: 100%;
    min-width: 320px;
    text-align:center;
}
#Container {
    width: 100%;
    margin: 0 auto;
    padding: 1.5em 0;
    max-width: 720px;
}
#ActiveArea {
    position:relative;
    /* height:600px; */
    /* width:600px; */
}



/* LINKS */
div.nav,
button.nav,
a.nav {
    cursor: pointer;
    font-size:1em;
    border:0;
    display:inline-block;
    background:#000;
    color:#fff;
    padding:0.5em 1.5em;
    text-decoration:none;
    font-family: "BMI", serif;
    border-radius: 2em;
    transition: opacity 250ms;
    line-height:1.25em;
}
div.nav:hover,
button.nav:hover,
a.nav:hover {
    opacity: 0.75;
}
a.reset {
    position:fixed;
    top:0.5em;
    left:0.5em;
    margin:0;
}


/* DOT NAV */
.DotNav {
    margin-top: -50%;
    margin-left: -50%;
    position:relative;
    width:100%;
    height:100%;
    z-index:1000;
    display:none;
    transition: opacity 150ms;
}
.DotNav.hidden {
    display: none;
    opacity: 0;
}
.DotNav.visible {
    display: block;
    opacity:1;
}
.DotNav .image {
    position:absolute;
    width:100%;
}
.DotNav .image img {
    opacity:0;
}
.DotNav .image.clicked img {
    animation: fadeIn 500ms linear forwards;
}
.DotNav .image.visible img {
    opacity: 1;
}
.DotNav .pulse {
    position:absolute;
    width:100%;
}
.DotNav .pulseWrapper {
    position:absolute;
    width:100%;
    animation: fadeIn 500ms linear forwards;
}
.DotNav .dotNavRing {
    animation: fadeIn 250ms linear forwards;
}
.DotNav .pulseWrapper.clicked, .DotNav .dotNavRing.clicked, .DotNav .dotHover.clicked {
    animation: fadeOut 500ms linear forwards;
}
.DotNav .dotHover {
    opacity:0;
}
.DotNav .dotHover.over {
    opacity:1;
    transition: opacity 150ms;
}
.DotNav .dotHover.out {
    opacity:0;
    transition: opacity 350ms;
}


.loading {
    animation: pulseLoad 1.5s linear infinite;
}

/* ANIMATION */
.pulse {
    animation: pulse 3s infinite;
}
.hidden {
    opacity: 0;
}
.removed {
    display: none;
    opacity: 0;
}
.fadeOut {
    animation: fadeOut 500ms linear forwards;
}
.fadeOutQuick {
    animation: fadeOut 250ms linear forwards;
}
.fadeOutSlow {
    display: block;
    animation: fadeOut 1000ms linear forwards;
}
.fadeIn {
    display: block;
    animation: fadeIn 500ms linear forwards;
}
.fadeInQuick {
    display: block;
    animation: fadeIn 250ms ease-out forwards;
}
.fadeInSlow {
    display: block;
    animation: fadeIn 1000ms linear forwards;
}


/* SIGIL */

#sigilEmbed {
    margin:0 auto;
    max-width:520px;
    max-height:520px;
    width:100%;
    overflow:hidden;
    position:relative;
}
#sigilEmbed #sigilSpacer {
    padding-top: 100%;
}
#sigilEmbed iframe {
    max-width:520px;
    margin:0 auto;
    border:0;
    overflow:hidden;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 100%;
}